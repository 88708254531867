<template>
  <b-row>
    <b-col
      cols="auto"
      v-for="(menu, idx) in menues"
      :key="`menu-${idx}`"
      class="mb-1"
      :class="{
        'pl-sm-0': idx > 0,
      }"
    >
      <b-button
        :variant="$route.name == menu.route ? 'primary' : 'outline-secondary'"
        @click="goTo(menu)"
      >
        {{ $t(menu.label) }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      menues: [
        {
          label: "breadcrumb.vehicleInfomration",
          route: "view-vehicle",
        },
        {
          label: "breadcrumb.inspectionReport",
          route: "view-vehicle-inspection-report",
        },
      ],
    };
  },
  methods: {
    goTo(menu) {
      if (menu.route == this.$route.name) {
        return;
      }

      this.$router.push({
        name: menu.route,
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
};
</script>