<template>
  <div>
    <load-profile></load-profile>
    <main-tab />
    <b-card class="mb-2">
      <b-row class="mb-2" v-if="isUpdate">
        <b-col md="12" class="text-right">
          <n-button-loading
            :variant="$can('update', resource) ? 'success' : 'secondary'"
            :loading="loading"
            :disabled="!$can('update', resource)"
            @submit="submit"
          >
            {{ $t("button.save") }}
          </n-button-loading>
        </b-col>
      </b-row>
      <b-row class="mb-2" v-else>
        <b-col md="6">
          <b-button variant="primary" @click="back">
            {{ $t("button.back") }}
          </b-button>
        </b-col>
        <b-col md="6" class="text-right">
          <b-button
            :variant="$can('update', resource) ? 'success' : 'secondary'"
            :disabled="!$can('updaste', resource) || loading"
            @click="isUpdate = true"
          >
            {{ $t("button.update") }}
          </b-button>
          <b-button
            class="ml-1"
            variant="secondary"
            v-if="$can('read', 'vehicle-log') || loading"
            @click="showLogModal()"
          >
            {{ $t("field.log") }}
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          ref="confirmForm"
          key="editForm"
          @submit="save"
          ok-variant="success"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input
            :key="`input-${key}`"
            :fields="fields"
            v-model="data"
            :initValue="initData"
            :disabled="!isUpdate"
          >
            <template #zoneId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="item.field.label"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :query="[
                      {
                        searchby: 'location_id',
                        searchoperator: '=',
                        search: Number(data.locationId),
                      },
                    ]"
                    :readonly="!isUpdate"
                    :ref="item.field.key"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :clearable="item.field.clearable"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-async-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
        </n-form-confirmation>
      </validation-observer>
    </b-card>

    <log-modal ref="logModal" />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton, BFormGroup } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./updateFormInput";
import moment from "moment";
import NButtonLoading from "@/components/NButtonLoading";
import LogModal from "./log/LogModal.vue";
import MainTab from "./MainTab.vue";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

const VehicleRepository = Repository.get("vehicle");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,

    NFormConfirmation,
    NInput,
    NButtonLoading,
    LogModal,
    MainTab,
    NAsyncSingleSelect,
  },
  watch: {
    "data.locationId"(newValue, oldValue) {
      if (oldValue && newValue != oldValue) {
        this.$refs.zoneId.clear();
      }
    },
    "data.bidBy"(value) {
      if (this.isUpdate) {
        if (value) {
          this.getBdPic();
        } else {
          this.data.bd = "";
        }
      }
    },
    "data.checkinAt"(value) {
      this.fields = this.fields.map((element) => {
        if (element.key == "locationId") {
          element.rules = value ? "required" : "";
        }
        return element;
      });
    },
  },
  data() {
    return {
      key: 1,
      isUpdate: false,
      loading: false,
      initData: {},
      data: {
        checkinAt: null,
        locationId: null,
        zoneId: null,
        bidBy: null,
        bd: null,
      },
    };
  },
  created() {
    this.show();
  },
  methods: {
    getBdPic() {
      this.loading = true;
      VehicleRepository.getBdPic(this.data.bidBy)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data.bd = data?.dbPicName;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    showLogModal() {
      this.$refs.logModal.show(this.data);
    },
    show() {
      this.loading = true;
      VehicleRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.fields = this.fields.map((element) => {
              if (element.key == "locationId") {
                element.rules = data.checkinAt ? "required" : "";
              }
              return element;
            });

            this.initData = {
              ...data,
              year: data.year && data.year >= 1000 ? `${data.year}-01-01` : "",
            };
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      VehicleRepository.update(this.$route.params.id, {
        ...this.data,
        year: this.data.year ? moment(this.data.year).format("YYYY") : "",
      })
        .then((response) => {
          this.loading = false;
          this.isUpdate = false;
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "vehicle";
    const route = "vehicle";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>
